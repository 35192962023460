import { useState, useRef } from "react";
import HCaptcha from '@hcaptcha/react-hcaptcha';
import emailjs from '@emailjs/browser';
import i18next from "i18next";
import FadeMessage from "../FadeMessage/FadeMessage";

const ContactForm = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [sentStatus, setSentStatus] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('');
    const [verified, setVerified] = useState(false)

    const form = useRef();
    const captcha = useRef();

    const clearFormData = () => {
        setName('');
        setEmail('');
        setSubject('')
        setMessage('');
        setVerified(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const stopProcess = verified === false
            || name.length === 0
            || email.length === 0
            || subject.length === 0
            || message.length === 0

        if (stopProcess)
            return;

        setIsLoading(true);
        
        const serviceId = process.env.REACT_APP_SERVICE_ID;
        const templateId = process.env.REACT_APP_TEMPLATE_ID;
        const publicKey = process.env.REACT_APP_PUBLIC_KEY;
        emailjs 
        .sendForm(serviceId, templateId, form.current, { publicKey: publicKey })
        .then(
            () => {
                console.log('SUCCESS!');
                clearFormData();
                captcha.current.resetCaptcha();
                setIsLoading(false);
                setSentStatus('ok');
            },
            (error) => {
                console.log('FAILED...', error.text);
                captcha.current.resetCaptcha();
                setIsLoading(false);
                setSentStatus('error')
            },

        );


    }


    const handleVerificationSuccess = (token, ekey) => {
        setVerified(true);
    }

    return (
        <form method="POST" ref={form} id="contactform" name="contactform" className="contactform" noValidate={true} onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="label" htmlFor="from_name">{i18next.t('ContactPage.fullnameHeader')}</label>
                        <input
                            type="text"
                            className="form-control"
                            name="from_name"
                            id="from_name"
                            placeholder="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="label" htmlFor="reply_to">{i18next.t('ContactPage.emailAddressHeader')}</label>
                        <input
                            type="email"
                            className="form-control"
                            name="reply_to"
                            id="reply_to"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <label className="label" htmlFor="subject">{i18next.t('ContactPage.subjectHeader')}</label>
                        <input
                            type="text"
                            className="form-control"
                            name="subject"
                            id="subject"
                            placeholder="Subject"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                        />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <label className="label" htmlFor="#">{i18next.t('ContactPage.messageHeader')}</label>
                        <textarea
                            className="form-control"
                            name="message"
                            id="message"
                            cols={30}
                            rows={4}
                            placeholder="Message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        >
                        </textarea>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <HCaptcha
                            sitekey="914ef198-2b54-4e24-a498-0fa8a8dabb6c"
                            onVerify={handleVerificationSuccess}
                            languageOverride="el"
                            ref={captcha}
                        />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <div style={{display: 'flex', columnGap: '1rem', alignItems:'center'}}>
                            <input type="submit" value={i18next.t('ContactPage.submitButtonText')} className="btn btn-primary" />
                            { isLoading && <Loader/> }
                            {sentStatus === 'ok' && <FadeMessage text="Το μήνυμα εστάλη επιτυχώς" status="ok"/> }
                            {sentStatus === 'error' && <FadeMessage text="Υπήρξε σφάλμα κατά την απόστολή. Παρακαλώ δοκιμάστε ξανά"  status="fail"/> }
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )

}

const Loader = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" width="100" height="50" style={{shapeRendering: 'auto', display: 'block', background: 'transparent'}}><g><circle fill="#e15b64" r="10" cy="50" cx="84">
    <animate begin="0s" keySplines="0 0.5 0.5 1" values="10;0" keyTimes="0;1" calcMode="spline" dur="0.8064516129032259s" repeatCount="indefinite" attributeName="r"></animate>
    <animate begin="0s" values="#e15b64;#cccccc;#e15b64;#cccccc;#e15b64" keyTimes="0;0.25;0.5;0.75;1" calcMode="discrete" dur="3.2258064516129035s" repeatCount="indefinite" attributeName="fill"></animate>
</circle><circle fill="#e15b64" r="10" cy="50" cx="16">
  <animate begin="0s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" values="0;0;10;10;10" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="3.2258064516129035s" repeatCount="indefinite" attributeName="r"></animate>
  <animate begin="0s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" values="16;16;16;50;84" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="3.2258064516129035s" repeatCount="indefinite" attributeName="cx"></animate>
</circle><circle fill="#cccccc" r="10" cy="50" cx="50">
  <animate begin="-0.8064516129032259s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" values="0;0;10;10;10" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="3.2258064516129035s" repeatCount="indefinite" attributeName="r"></animate>
  <animate begin="-0.8064516129032259s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" values="16;16;16;50;84" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="3.2258064516129035s" repeatCount="indefinite" attributeName="cx"></animate>
</circle><circle fill="#e15b64" r="10" cy="50" cx="84">
  <animate begin="-1.6129032258064517s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" values="0;0;10;10;10" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="3.2258064516129035s" repeatCount="indefinite" attributeName="r"></animate>
  <animate begin="-1.6129032258064517s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" values="16;16;16;50;84" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="3.2258064516129035s" repeatCount="indefinite" attributeName="cx"></animate>
</circle><circle fill="#cccccc" r="10" cy="50" cx="16">
  <animate begin="-2.4193548387096775s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" values="0;0;10;10;10" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="3.2258064516129035s" repeatCount="indefinite" attributeName="r"></animate>
  <animate begin="-2.4193548387096775s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" values="16;16;16;50;84" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="3.2258064516129035s" repeatCount="indefinite" attributeName="cx"></animate>
</circle><g></g></g></svg>
    )
}



export default ContactForm