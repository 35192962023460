import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEl from './locales/el.json';
import translationEn from './locales/en.json'

i18n.use(initReactI18next).init({
  lng: "el",
  fallbackLng: "el",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    el: {
        translation: translationEl 
    },
    en: {
        translation: translationEn
    }
  },
});

export default i18n;