import React from "react";
import i18next from "i18next";
import GoogleMap from "../../components/map/map";
import ContactForm from "../../components/contactForm/contactForm";
import { FaPhone, FaGlobe, FaMapMarkerAlt, FaEnvelope } from "react-icons/fa";
class Contact extends React.Component {
    
    render() { 
        return ( 
            <div className="container">
                <div className="row justify-content-center margined-row">
                    <div className="col-md-12">
                        <div className="wrapper">
                            <div className="row no-gutters mb-5">
                                <div className="col-lg-7">
                                    <div className="contact-wrap w-100 p-md-5 p-4">
                                        <h3 className="mb-4">{i18next.t('ContactPage.contactFormHeader')}</h3>
                                        <div id="form-message-warning" className="mb-4"></div>
                                        <div id="form-message-success" className="mb-4">
                                            Your message was sent, thank you!
                                        </div>
                                        <ContactForm />
                                        
                                    </div>
                                </div>
                                <div className="col-lg-5 d-flex align-items-stretch">
                                    <div id="map" className='google-map' style={{ position: 'relative', overflow: 'hidden', minHeight: '595px' }}>
                                        <div style={{ height: '100%', width: '100%', position: 'absolute', top: '0px', left: '0px', backgroundColor: 'rgb(229, 227, 223)' }}>
                                            <GoogleMap />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-gapped">
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="dbox w-100 text-center">
                                                <div className="icon d-flex align-items-center justify-content-center">
                                                    <FaMapMarkerAlt/>
                                                </div>
                                                <div className="text">
                                                    <p><span>{i18next.t('ContactPage.addressHeader')}</span> <br/> <p style={{color: '#9a0000'}}>{i18next.t('ContactPage.addressText')}</p></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                        <div className="dbox w-100 text-center">
                                            <div className="icon d-flex align-items-center justify-content-center">
                                                <FaPhone/>
                                            </div>
                                            <div className="text">
                                                <p><span>{i18next.t('ContactPage.phoneHeader')}</span> <br/> <a href="tel://2296033856">{i18next.t('ContactPage.phoneText')}</a></p>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                 <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="dbox w-100 text-center">
                                                <div className="icon d-flex align-items-center justify-content-center">
                                                    <FaEnvelope/>
                                                </div>
                                                <div className="text">
                                                    <p><span>{i18next.t('ContactPage.emailHeader')}</span> <br/> <a href="mailto:info@motherlearn.gr">{i18next.t('ContactPage.emailText')}</a></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="dbox w-100 text-center">
                                                <div className="icon d-flex align-items-center justify-content-center">
                                                    <FaGlobe/>
                                                </div>
                                                <div className="text">
                                                    <p><span>{i18next.t('ContactPage.websiteHeader')}</span> <br/> <a href="http://www.motherlearn.gr">{i18next.t('ContactPage.websiteText')}</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default Contact;