
import React from "react";
import styles from './card.module.scss'
import { NavLink } from 'react-router-dom';
import i18next from "i18next";
import person from '../../assets/images/person1.png'
import { FaCaretRight } from "react-icons/fa";

class Card extends React.Component {
    
    render() { 
        return (
            <div className={styles.blog_slider}>
                <div className={styles.blog_slider__item}>
                    <div className={styles.blog_slider__img}>

                        <img src={person} alt="Maria Mavroeidi" />
                    </div>
                    <div className={styles.blog_slider__content}>
                        <span className={styles.blog_slider__code}>2 Σεπτεμβρίου 2024</span>
                        <div className={styles.blog_slider__text}>
                            {i18next.t('LandingPage.AboutSection.text')}
                        </div>

                        <NavLink className={styles.blog_slider__button} to="/about">
                            {i18next.t('LandingPage.AboutSection.buttonText')}
                            <FaCaretRight />
                        </NavLink>

                    </div>
                </div>
            </div >
        )
    }
}
 
export default Card;