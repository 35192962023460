import React from 'react';
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import './assets/css/bootstrap.css';
import './assets/css/responsive.css';
import './assets/css/styles.css';
import Navbar from './components/navBar/Navbar';
import Home from './pages/home/home';
import About from './pages/about/about';
import Faq from './pages/faq/faq';
import Contact from './pages/contact/contact';
import ScrollToTop from './scrollToTop';

function App() {
  return (
    <div className="landing_container">
      <Router>
        <ScrollToTop />
          <Navbar />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
          <section className="container-fluid footer_section">
            <p>
              Copyright &copy; {new Date().getFullYear()} All Rights Reserved By Salva
            </p>
          </section>
      </Router>
    </div>
  );
}

export default App;
