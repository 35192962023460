import styles from './FadeMessage.module.scss';

const FadeMessage = (props) => {
    const {text, status} = props;
    const statusClass = status === 'ok'? styles.fadeInAndOut_success: styles.fadeInAndOut_fail
    return (
        <div className={`${styles.fadeInAndOut} ${statusClass}`}>{text}</div>
    )
}

export default FadeMessage