import React from "react";
import Card from "../../components/card/card";
import i18next from "i18next";
import k6booth3 from '../../assets/images/k6booth3.png';
import mlLogo from '../../assets/images/Motherlearn_Logo_Only.png';
import clilImg from '../../assets/images/clil.png';
class Home extends React.Component {
    
    render() { 

        return ( 
            <>
                <section className="hero_section">
                    <div className="hero-container container">
                    <div className="hero_detail-box">

                        <span className='over_banner'>
                        {i18next.t('LandingPage.HeroSection.OverBanner')}
                        </span>
                        <img src={mlLogo} alt='logo' className="hero_detail-box-img" />
                        <span className='under_banner'>
                        {i18next.t('LandingPage.HeroSection.UnderBanner')}
                        </span>
                        <span className='under_banner'>
                        {i18next.t('LandingPage.HeroSection.UnderBannerMoto')}
                        </span>
                    </div>
                    <div className="hero_img-container">
                        <div>
                        <img src={k6booth3} alt="booth" className="img-fluid" />
                        </div>
                    </div>
                    </div>
                </section>

                <section className="about_section layout_padding curved1 layout_colored2">
                    <div className="container">
                        <h2 className="main-heading ">
                            {i18next.t('LandingPage.AboutSection.Head')}
                        </h2>
                        <Card />
                    </div>
                </section>

                <section className="client_section layout_padding layout_colored">
                    <div className="container">
                    
                    <div className="layout_padding2">
                        <div className="client_container d-flex flex-column clil_card">
                        <div className="client_detail d-flex align-items-center">
                            <div className="d-flex justify-content-center" style={{ width: '100%' }}>
                            <img src={clilImg} className="clil-img"/>
                            </div>
                        </div>
                        <div className="client_text mt-4" dangerouslySetInnerHTML={{ __html: i18next.t('LandingPage.ClilSection.text') }} />
                        </div>
                    </div>
                    </div>
                </section>
            </>
         );
    }
}
 
export default Home;