import React from "react";
import i18next from "i18next";
import person from '../../assets/images/person1.png'

class About extends React.Component {

    render() { 
        return ( 
            <div className="container">
                <div className="row margined-row">
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-md-12 order-1 order-md-1 mt-4 pt-2 mt-sm-0 opt-sm-0">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-6">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 mt-4 pt-2">
                                            <div className="rounded border-0  overflow-hidden">
                                                <img src={person} className="img-fluid rounded-circle shadow-4-strong"
                                                    alt="Image" />
                                                <div className="img-overlay bg-dark"></div>
                                            </div>
                                        </div>


                                    </div>

                                </div>


                                <div className="col-lg-6 col-md-6 col-6">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12">
                                            <div className="rounded border-0 overflow-hidden">
                                                <div className="about-main-name colored-red">
                                                    <h1>{i18next.t('CvPage.title')}</h1>
                                                    <span>{i18next.t('CvPage.subtitle1')} <br /> {i18next.t('CvPage.subtitle2')}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="rounded border-0 overflow-hidden">
                                                <div className="about-main-name">
                                                    <span className='about-moto'>{i18next.t('CvPage.subtext')}</span>
                                                    <span className='about-moto-author'>{i18next.t('CvPage.subtext2')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>

                        <div className="col-lg-12 col-md-12 col-12 order-2 order-md-2">
                            <div className="card-box">
                                <h5 className="mb-4 text-uppercase colored-red">
                                    <i className="fa fa-graduation-cap mr-2" aria-hidden="true"></i>
                                    {i18next.t('CvPage.studies.title')}
                                </h5>
                                <ul className="list-unstyled timeline-sm">
                                    <li className="timeline-sm-item">
                                        <span className="timeline-sm-date">{i18next.t('CvPage.studies.time1')}</span>
                                        <h5 className="mt-0 mb-1">{i18next.t('CvPage.studies.study1')}</h5>
                                        <p>{i18next.t('CvPage.studies.study1_2')}</p>
                                    </li>
                                    <li className="timeline-sm-item">
                                        <span className="timeline-sm-date">{i18next.t('CvPage.studies.time2')}</span>
                                        <h5 className="mt-0 mb-1">{i18next.t('CvPage.studies.study2')}</h5>
                                        <p>{i18next.t('CvPage.studies.study2_2')}</p>
                                        <p>{i18next.t('CvPage.studies.study2_3')}</p>
                                    </li>
                                </ul>
                            </div>
                            <div className="card-box">
                                <h5 className="mb-3 mt-4 text-uppercase colored-red">
                                    <i className="fa fa-briefcase mr-2" aria-hidden="true"></i>
                                    {i18next.t('CvPage.experience.title')}
                                </h5>
                                <ul className="list-unstyled timeline-sm">
                                    <li className="timeline-sm-item">
                                        <span className="timeline-sm-date">{i18next.t('CvPage.experience.time1')}</span>
                                        <h5 className="mt-0 mb-1">{i18next.t('CvPage.experience.exp1')}</h5>
                                        <p>{i18next.t('CvPage.experience.exp1_2')}</p>
                                    </li>
                                    <li className="timeline-sm-item">
                                        <span className="timeline-sm-date">{i18next.t('CvPage.experience.time2')}</span>
                                        <h5 className="mt-0 mb-1">{i18next.t('CvPage.experience.exp2')}</h5>
                                        <p>{i18next.t('CvPage.experience.exp2_2')}</p> 
                                    </li>
                                    <li className="timeline-sm-item">
                                        <span className="timeline-sm-date">{i18next.t('CvPage.experience.time3')}</span>
                                        <h5 className="mt-0 mb-1">{i18next.t('CvPage.experience.exp3')}</h5>
                                        <p>{i18next.t('CvPage.experience.exp3_2')}</p>
                                    </li>
                                    <li className="timeline-sm-item">
                                        <span className="timeline-sm-date">{i18next.t('CvPage.experience.time4')}</span>
                                        <h5 className="mt-0 mb-1">{i18next.t('CvPage.experience.exp4')}</h5>
                                        <p>{i18next.t('CvPage.experience.exp4_2')}</p>
                                    </li>

                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}
 
export default About;