import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';

const apiKey = 'AIzaSyA_VnLaw2zW2ErGPyYoWJYp1Mvlb-CHyNE';
const position = { lat: 38.003103, lng: 23.419522 };

const GoogleMap = () => {
    return (
        <APIProvider apiKey={apiKey}>
            <Map defaultCenter={position} defaultZoom={14}>
                <Marker position={position} />
            </Map>
        </APIProvider>
    );
}

export default GoogleMap