import React from "react";
import styles from './faq.module.scss'
import i18next from "i18next";

class Faq extends React.Component {
   
    render() { 
        return ( 
            <div className="container qa-page">
                <div className="margined-row">
                    
                    <div className={styles.masonry_container}>
                        <div className={styles.row}>
                            {
                                Array.from(Array(9).keys()).map((item, index) =>
                                    QaBox(i18next.t(`QAPage.questions.qa${index + 1}.q`), i18next.t(`QAPage.questions.qa${index + 1}.a`), index)
                                )
                            }

                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

const QaBox = (header, text, index) => {
    const shades = ['#C53721', '#F05E41', '#FF8362', '#FFAA85']
    const shades2 = ['#98003D', '#7D2463', '#543C73', '#32466C', '#2F4858']
    const backgroundColor = randChoice(shades2);
    const imageNo = (index + 1) % 7 === 0 ? 7 : (index + 1) % 7;
    const overCArt = require(`../../assets/images/qa/${imageNo}.png`);
    return (
        <div className={`col-md-12 col-lg-12 ${styles.item}`}>
            <div className={styles.box}>
                <div>
                    <img src={overCArt} />
                </div>

                <h4>{header}</h4>
                <div className={styles.description} dangerouslySetInnerHTML={{ __html: text }} />
            </div>
        </div>
    )
}

const randChoice = (arr) => {
    return arr[Math.floor(Math.random() * arr.length)]
}
 
export default Faq;