import React from "react";
import { NavLink } from "react-router-dom";
import i18next from "i18next";
import logo from '../../assets/images/logo_m.png'
import { FaBars } from "react-icons/fa";

class Navbar extends React.Component {

    constructor(props) {
        super(props);
        this.navRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }


    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }


    render() { 
        return ( 
            <section className="top_container">
                <header className="header_section">
                    <div className="container full-width-container">
                        <nav className="navbar navbar-expand-lg custom_nav-container ">
                            <NavLink to="/" className="nav-link navbar-brand">
                                <img src={logo} alt="booth" />
                                <span className='colored-red'>
                                    MotherLearn
                                </span>
                            </NavLink>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon" onClick={this.toggleNav}>
                                    <FaBars />
                                </span>
                            </button>

                            <div className="collapse navbar-collapse" id="navbarSupportedContent" ref={this.navRef}>
                                <div className="d-flex ml-auto flex-column flex-lg-row align-items-center navbar-collapse-inner">
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <NavLink to="/" className="nav-link" activeStyle onClick={this.closeNav}>
                                                {i18next.t('LandingPage.TopContainer.TopMenu.MenuItem1')}
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/about" className="nav-link"  activeStyle onClick={this.closeNav}>
                                                {i18next.t('LandingPage.TopContainer.TopMenu.MenuItem2')}
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/faq" className="nav-link"  activeStyle onClick={this.closeNav}>
                                                {i18next.t('LandingPage.TopContainer.TopMenu.MenuItem3')}
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/contact" className="nav-link"  activeStyle onClick={this.closeNav}>
                                                {i18next.t('LandingPage.TopContainer.TopMenu.MenuItem4')}
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </header >
            </section >
        );
    }

    toggleNav = (e) => {
        if (this.navRef && this.navRef.current)
            this.navRef.current.classList.toggle('show');
    }

    closeNav = (e) => {
         if (this.navRef && this.navRef.current)
            this.navRef.current.classList.remove('show');
    }

    handleClickOutside = (e) => {
        if (this.navRef && !this.navRef.current.contains(e.target)) 
            this.navRef.current.classList.remove('show');
    }
}
 
export default Navbar;